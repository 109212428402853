var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-header-fixed', {
    staticClass: "header--lnb-all"
  }, [_c('u-header-body-basic', {
    staticClass: "d-none d-xl-block",
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('logo-primary', [_vm._v("동대문구 패션봉제지원센터")])];
      },
      proxy: true
    }, {
      key: "center",
      fn: function () {
        return [_c('u-gnb-underline', {
          staticClass: "text-center",
          attrs: {
            "gnbName": "gnb",
            "lnbTag": "u-lnb-all-white"
          }
        })];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }