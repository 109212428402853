<template>
    <div class="footer-slider">
        <swiper class="swiper" :options="swiperOptions">
            <swiper-slide v-for="(item, index) in items" :key="index">
                <v-card flat rounded="pill" class="footer-slider__inner">
                    <img class="footer-slider__img" :src="item.img" />
                </v-card>
            </swiper-slide>

            <div slot="pagination" class="swiper-control" @click.prevent>
                <swiper-button-primary />
                <swiper-button-primary next />
            </div>
        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import SwiperPaginationPrimary from "@/components/publish/parents/swipers/swiper-pagination-primary.vue";
import SwiperBtnWrapPrimary from "@/components/publish/parents/swipers/swiper-btn-wrap-primary.vue";
import SwiperButtonPrimary from "@/components/publish/parents/swipers/swiper-button-primary.vue";
import BtnArrowPrimary from "@/components/publish/parents/buttons/btn-arrow-primary.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        SwiperPaginationPrimary,
        SwiperBtnWrapPrimary,
        SwiperButtonPrimary,
        BtnArrowPrimary,
    },
    props: {},
    data() {
        return {
            swiperOptions: {
                allowTouchMove: true,
                centeredSlides: false,
                slidesPerView: 2,
                spaceBetween: 6,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                speed: 500,
                // loop: true,
                navigation: {
                    nextEl: ".footer-slider .swiper-button-next",
                    prevEl: ".footer-slider .swiper-button-prev",
                },
                breakpoints: {
                    576: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 16,
                    },
                    1024: {
                        slidesPerView: 5,
                        spaceBetween: 16,
                    },
                    1200: {
                        slidesPerView: 7,
                        spaceBetween: 16,
                    },
                },
            },

            items: [
                { img: "/images/logo/mss-logo.png" },
                { img: "/images/logo/semas-logo.png" },
                { img: "/images/logo/seouldesign-logo.png" },
                { img: "/images/logo/dfmc-logo.png" },
                { img: "/images/logo/ddm-logo.png" },
                { img: "/images/logo/ddm-reserve-logo.png" },
                { img: "/images/logo/semas-logo.png" },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// footer-slider
.footer-slider {
    position: relative;
    width: 100%;
    padding: 14px 0;

    &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 48px;
        padding: 2px 10px;
        background-color: #fff;
    }
    &__img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        border-radius: 0 !important;
    }

    .swiper-container,
    .swiper-control {
        max-width: calc(var(--container) + ((var(--container-gutter) + 44px) * 2));
    }
    .swiper-container {
        padding: 0 var(--container-gutter);
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            z-index: 2;
            display: block;
            width: calc(var(--container-gutter) + 44px);
            height: 100%;
            background-color: var(--v-grey-base);
        }
        &::before {
            left: -2px;
        }
        &::after {
            right: -2px;
        }
    }
    .swiper-control {
        z-index: 3;
        height: auto;
    }
    .swiper-container::before,
    .swiper-container::after,
    .swiper-control {
        display: none;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .footer-slider {
        padding: 20px 0;
        &__inner {
            padding: 2px 14px;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .footer-slider {
        .swiper-container {
            padding: 0 calc(var(--container-gutter) + 44px);
        }
        .swiper-container::before,
        .swiper-container::after,
        .swiper-control {
            display: block;
        }
    }
}
</style>
