var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-tit-wrap-default', _vm._b({
    class: [_vm.titWrapClass, _vm.titWrapSize, _vm.titWrapAlign]
  }, 'u-tit-wrap-default', _vm.$attrs, false), [this.$slots['subtit'] ? _c('u-tit-subtit', {
    staticClass: "tit--xs",
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "100"
    }
  }, [_vm._t("subtit")], 2) : _vm._e(), this.$slots['tit'] ? _c('u-tit-icon', {
    class: [_vm.titClass, _vm.titSize],
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._t("default")], 2) : _c('u-tit-icon', {
    class: [_vm.titClass, _vm.titSize],
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "200"
    }
  }, [_vm._t("default")], 2), this.$slots['txt'] ? _c('u-txt-default', {
    staticClass: "txt--dark",
    class: [_vm.txtClass, _vm.txtSize],
    attrs: {
      "data-aos": "fade-up",
      "data-aos-delay": "300"
    }
  }, [_vm._t("txt")], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }