<template>
    <app-primary isMain class="app--main">
        <main-visual />

        <main-section-primary titWrapClass="text-center">
            <template #tit> 공간안내 </template>
            <template #txt> 패션봉제지원센터에서 이용가능한 공간을 확인해보세요. </template>

            <!-- <u-img-txt-items :items="facilitySpace" /> -->
            <u-img-txt-items :items="spaces" />

            <div class="btn-wrap btn-wrap--lg">
                <v-row align="center" justify="center" class="v-row--sm">
                    <v-col cols="auto">
                        <btn-text-primary to="/facility-space/YONGSIN">MORE</btn-text-primary>
                    </v-col>
                </v-row>
            </div>
        </main-section-primary>

        <main-section-primary class="grey lighten-5">
            <template #tit> 장비안내 </template>
            <template #txt> 패션봉제지원센터에서 이용가능한 장비를 확인해보세요. </template>

            <!-- <u-img-txt-items :items="facilityEquipment" /> -->
            <u-img-txt-items :items="equipments" />

            <div class="btn-wrap btn-wrap--lg">
                <v-row align="center" justify="center" class="v-row--sm">
                    <v-col cols="auto">
                        <btn-text-primary to="/cuttingroom-equipment?code=CUTTINGROOM">공용재단실</btn-text-primary>
                    </v-col>
                    <v-col cols="auto">
                        <btn-text-primary to="/studio-equipment/YONGSIN?code=STUDIO">공용작업실</btn-text-primary>
                    </v-col>
                </v-row>
            </div>
        </main-section-primary>

        <main-section-primary>
            <template #pageSectionHead>
                <div class="page-section__bg" />
            </template>
            <v-row class="row--xl">
                <v-col cols="12" md="5">
                    <tit-wrap-secondary>
                        공지사항
                        <template #colRight>
                            <btn-text-primary to="/notice">MORE</btn-text-primary>
                        </template>
                    </tit-wrap-secondary>

                    <main-notice-slider />
                </v-col>
                <v-col cols="12" md="7">
                    <tit-wrap-secondary>
                        교육갤러리
                        <template #colRight>
                            <btn-text-primary to="/edu-gallery">MORE</btn-text-primary>
                        </template>
                    </tit-wrap-secondary>

                    <v-row >
                        <v-col v-for="(gallery, index) in galleries" :key="index" cols="6">
                            <u-card-hover-border-bottom elevation="15" to="/edu-gallery">
                                <v-img cover :aspect-ratio="326 / 288" max-width="324" :src="gallery.image" class="mx-auto">
                                    <template v-slot:placeholder>
                                        <no-image />
                                    </template>
                                </v-img>
                            </u-card-hover-border-bottom>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </main-section-primary>

        <popup-layer />
    </app-primary>
</template>

<script>
import api from "@/api";
import AppPrimary from "@/sets/parents/apps/app-primary.vue";
import PopupLayer from "@/components/client/popup/popup-layer.vue";

import MainSectionPrimary from "@/sets/parents/mains/main-section-primary.vue";
import TitWrapSecondary from "@/components/publish/parents/typography/tit-wrap-secondary.vue";
import BtnPrimary from "@/components/publish/parents/buttons/btn-primary.vue";
import BtnTextPrimary from "@/components/publish/parents/buttons/btn-text-primary.vue";
import MainVisual from "@/sets/styles/mains/main-visual.vue";
import MainNoticeSlider from "@/components/publish/styles/slider//main-notice-slider.vue";
import UImgTxtItems from "@/components/publish/styles/items/u-img-txt-items.vue";
import UCardHoverBorderBottom from "@/components/publish/styles/cards/u-card-hover-border-bottom.vue";
import NoImage from "@/components/publish/styles/cards/no-image.vue";

export default {
    components: {
        AppPrimary,
        PopupLayer,
        MainSectionPrimary,
        TitWrapSecondary,
        BtnPrimary,
        BtnTextPrimary,

        MainVisual,
        MainNoticeSlider,
        UImgTxtItems,
        UCardHoverBorderBottom,
        NoImage,
    },
    props: {
        isMain: { type: Boolean, default: true },
    },
    data() {
        return {
            spaces: [],
            equipments: [],
            galleries: []
        };
    },
    mounted() {
        this.search();
    },
    methods: {
        async search() {
            try {
                let [{ spaces }, { equipments }, { galleries }] = await Promise.all([
                    api.v1.space.gets({ headers: { limit: 8 }, params: { isMain: true } }), 
                    api.v1.equipment.gets({ headers: { limit: 8 }, params: { isMain: true } }),
                    api.v1.gallery.gets({ headers: { limit: 2 }, params: { isMain: true}})
                ]);

                this.spaces = spaces;
                this.equipments = equipments;
                this.galleries = galleries;
            } catch (error) {
                console.error(error);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.main-section-primary {
    .page-section__bg {
        top: auto;
        bottom: 0;
        height: 50%;
        background-color: #fbf7f9;
    }
}
</style>
