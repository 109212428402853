var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "img-txt-items"
  }, [_c('v-row', {
    staticClass: "my-n12px my-md-n20px"
  }, _vm._l(_vm.items, function (item, index) {
    return _c('v-col', {
      key: index,
      staticClass: "py-12px py-md-20px",
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('card-tertiary', {
      attrs: {
        "to": _vm.routingPath(item)
      }
    }, [_c('v-img', {
      staticClass: "mx-auto",
      attrs: {
        "cover": "",
        "aspect-ratio": 282 / 164,
        "src": item.thumb
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function () {
          return [_c('no-image')];
        },
        proxy: true
      }], null, true)
    }), _c('div', {
      staticClass: "py-16px py-md-24px"
    }, [_c('tit-primary', {
      staticClass: "mb-8px",
      attrs: {
        "titSize": "tit--xs"
      }
    }, [_vm._v(_vm._s(item.title))]), _c('u-txt-items', [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('u-txt-items', {
      attrs: {
        "txtItem": ""
      },
      scopedSlots: _vm._u([{
        key: "tit",
        fn: function () {
          return [_vm._v("이용시간")];
        },
        proxy: true
      }, {
        key: "txt",
        fn: function () {
          return [_vm._v(_vm._s(item.time))];
        },
        proxy: true
      }], null, true)
    })], 1), item.capacity ? _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('u-txt-items', {
      attrs: {
        "txtItem": ""
      },
      scopedSlots: _vm._u([{
        key: "tit",
        fn: function () {
          return [_vm._v("면적")];
        },
        proxy: true
      }, {
        key: "txt",
        fn: function () {
          return [_vm._v(_vm._s(item.capacity))];
        },
        proxy: true
      }], null, true)
    })], 1) : _vm._e(), item.quantity ? _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('u-txt-items', {
      attrs: {
        "txtItem": ""
      },
      scopedSlots: _vm._u([{
        key: "tit",
        fn: function () {
          return [_vm._v("수량")];
        },
        proxy: true
      }, {
        key: "txt",
        fn: function () {
          return [_vm._v(_vm._s(item.quantity))];
        },
        proxy: true
      }], null, true)
    })], 1) : _vm._e(), _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('u-txt-items', {
      attrs: {
        "txtItem": ""
      },
      scopedSlots: _vm._u([{
        key: "tit",
        fn: function () {
          return [_vm._v("위치안내")];
        },
        proxy: true
      }, {
        key: "txt",
        fn: function () {
          return [_vm._v(_vm._s(item.location))];
        },
        proxy: true
      }], null, true)
    })], 1)], 1)], 1)], 1)], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }