<template>
    <v-card v-bind="$attrs" flat rounded="0" class="card-hover-border-bottom">
        <slot />
    </v-card>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.card-hover-border-bottom {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        border-bottom: 4px solid var(--v-primary-base);
        -webkit-transition: all ease-out 0.5s;
        -ms-transition: all ease-out 0.5s;
        transition: all ease-out 0.5s;
        opacity: 0;
        visibility: hidden;
    }
    &:hover {
        &::before {
            opacity: 1;
            visibility: visible;
        }
    }
}
</style>
