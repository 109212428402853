var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-visual"
  }, [_c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_c('swiper-slide', [_c('v-card', {
    staticClass: "main-visual__inner",
    attrs: {
      "flat": "",
      "rounded": "0"
    }
  }, [_c('div', {
    staticClass: "main-visual__content"
  }, [_c('div', {
    attrs: {
      "data-aos": "zoom-in",
      "data-aos-duration": "1000"
    }
  }, [_c('div', {
    staticClass: "font-size-16 font-size-md-18 font-size-lg-24"
  }, [_c('b', {
    staticClass: "font-size-18 font-size-md-20 font-size-lg-36"
  }, [_vm._v("동대문구 패션봉제복합지원센터")]), _vm._v("는 "), _c('br'), _vm._v("스마트 제조장비, 공용작업장, 제품개발, 일감연계, 소공인 역량강화, 온라인 마케팅 등 "), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v("지원을 통해 "), _c('b', [_vm._v("소공인 혁신기반을 조성")]), _vm._v("합니다.")])])]), _c('div', {
    staticClass: "main-visual__img",
    staticStyle: {
      "background-image": "url(/images/main/main-visual.jpg)"
    }
  })])], 1), _c('swiper-slide', [_c('v-card', {
    staticClass: "main-visual__inner",
    attrs: {
      "flat": "",
      "rounded": "0"
    }
  }, [_c('div', {
    staticClass: "main-visual__content"
  }, [_c('div', {
    attrs: {
      "data-aos": "zoom-in",
      "data-aos-duration": "1000"
    }
  }, [_c('div', {
    staticClass: "font-size-16 font-size-md-18 font-size-lg-24"
  }, [_c('b', {
    staticClass: "font-size-18 font-size-md-20 font-size-lg-36"
  }, [_vm._v("동대문구 패션봉제복합지원센터")]), _vm._v("는 "), _c('br'), _vm._v("스마트 제조장비, 공용작업장, 제품개발, 일감연계, 소공인 역량강화, 온라인 마케팅 등 "), _c('br', {
    staticClass: "d-none d-sm-block"
  }), _vm._v("지원을 통해 "), _c('b', [_vm._v("소공인 혁신기반을 조성")]), _vm._v("합니다.")])])]), _c('div', {
    staticClass: "main-visual__img",
    staticStyle: {
      "background-image": "url(/images/main/main-visual2.jpg)"
    }
  })])], 1), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
      }
    },
    slot: "pagination"
  }, [_c('swiper-pagination-primary')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }