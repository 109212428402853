<template>
    <u-btn-flat-tile v-bind="$attrs" :color="color" @click="$emit('click')">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-btn-flat-tile>
</template>

<script>
import UBtnFlatTile from "@/components/publish/styles/buttons/u-btn-flat-tile.vue";

export default {
    props: {
        color: { type: String, default: "primary" },
    },
    components: {
        UBtnFlatTile,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-btn--has-bg {
    &.grey {
        color: #fff !important;
    }
}
</style>
