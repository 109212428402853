import axios from "@/plugins/axios";

import apply from "./apply";
import auction from "./auction";
import banners from "./banners";
import boards from "./boards";
import center from "./center";
import education from "./education";
import fairs from "./fairs";
import forms from "./forms";
import gallery from "./gallery";
import levels from "./levels";
import messages from "./messages";
import popups from "./popups";
import setting from "./setting";
import lawyers from "./lawyers";
import practice from "./practice";
import schedule from "./schedule";
import shop from "./shop";
import space from "./space";
import statistics from "./statistics";
import terms from "./terms";
import users from "./users";
import equipment from "./equipment";
export default {
    apply,
    auction,
    banners,
    boards,
    center,
    education,
    fairs,
    forms,
    gallery,
    levels,
    messages,
    popups,
    schedule,
    setting,
    lawyers,
    practice,
    shop,
    space,
    statistics,
    terms,
    users,
    equipment,
    email: {
        post({ to, subject, html }) {
            return axios.post("/api/console/email", { to, subject, html }).then((result) => result.data);
        },
    },
    message: {
        post({ to, subject, message }) {
            return axios.post("/api/console/message", { to, subject, message }).then((result) => result.data);
        },
    },
};
