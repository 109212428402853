<template>
    <u-card-border-bottom v-bind="$attrs" class="card-tertiary v-card--none-active-bg">
        <template v-if="innerClass || cardInnerSize">
            <div class="card-inner" :class="[innerClass, cardInnerSize]">
                <slot />
            </div>
        </template>
        <template v-else>
            <slot />
        </template>
    </u-card-border-bottom>
</template>

<script>
import UCardBorderBottom from "@/components/publish/styles/cards/u-card-border-bottom.vue";

export default {
    props: {
        innerClass: { type: String, default: "" },
        cardInnerSize: { type: String, default: "" },
    },
    components: {
        UCardBorderBottom,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.card-tertiary {
    background-color: transparent;
    &.v-card--link:hover {
        ::v-deep {
            .v-divider {
                border-color: var(--v-primary-base) !important;
                -webkit-transition: all ease-out 0.5s;
                -ms-transition: all ease-out 0.5s;
                transition: all ease-out 0.5s;
            }
        }
    }
}
</style>
