<template>
    <u-card-outlined-tile v-bind="$attrs" class="border-light">
        <template v-if="innerClass || cardInnerSize">
            <div class="card-inner" :class="[innerClass, cardInnerSize]">
                <slot />
            </div>
        </template>
        <template v-else>
            <slot />
        </template>
    </u-card-outlined-tile>
</template>

<script>
import UCardOutlinedTile from "@/components/publish/styles/cards/u-card-outlined-tile.vue";

export default {
    props: {
        innerClass: { type: String, default: "" },
        cardInnerSize: { type: String, default: "" },
    },
    components: {
        UCardOutlinedTile,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
