<template>
    <div style="position: fixed; top: 0; left: 0; z-index: 999">
        <transition v-for="popup in popups" :key="popup._id">
            <component :is="skinComponent" :popup="popup"></component>
        </transition>
    </div>
</template>

<script>
import api from "@/api";

export default {
    props: {
        skin: { type: String, default: "basic" },
    },
    data() {
        return {
            popups: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            var { popups } = await api.v1.popups.gets();
            this.popups = popups;
        },
    },
    computed: {
        skinComponent() {
            return () => import(`./skin/${this.$props.skin}/view.vue`);
        },
    },
};
</script>

// <style lang="scss" scoped>
// .popup-layer {
//     position: absolute;
//     z-index: 999;
//     background-color: #fff;
// }
// .popup-layer__body {
//     overflow: hidden;
// }
// .popup-layer-content {
//     line-height: 0;
// }
// .popup-layer__foot {
//     padding: 4px 8px;
//     background-color: #f7f7f7;
// }
// @media (max-width: 576px) {
//     .popup-layer__body {
//         width: 100% !important;
//         height: auto !important;
//     }
// }
// @media (max-width: 1024px) {
//     .popup-layer {
//         top: 100px !important;
//         left: 12px !important;
//     }
//     .popup-layer__inner {
//         width: calc(100vw - 24px) !important;
//     }
//     .popup-layer__body {
//         width: 100%;
//         height: auto !important;
//     }
// }
</style>
