var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    staticClass: "card-hover-border-bottom",
    attrs: {
      "flat": "",
      "rounded": "0"
    }
  }, 'v-card', _vm.$attrs, false), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }