var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.txtItem ? _c('v-row', {
    staticClass: "txt-item",
    class: _vm.size == 'lg' ? 'row--sm' : _vm.itemSize
  }, [_c('v-col', {
    staticClass: "txt-item__col-tit",
    class: [_vm.colClass, _vm.colTitClass],
    attrs: {
      "cols": _vm.titCols,
      "md": _vm.titMd
    }
  }, [_c('txt-secondary', {
    staticClass: "txt--dark",
    class: [_vm.size == 'lg' ? 'txt--lg' : '', _vm.titClass],
    style: _vm.size == 'lg' ? 'min-width:64px' : _vm.titWidth
  }, [_vm._t("tit")], 2)], 1), _c('v-col', {
    staticClass: "txt-item__col-txt",
    class: [_vm.colClass, _vm.colTxtClass],
    attrs: {
      "cols": _vm.txtCols,
      "md": _vm.txtMd
    }
  }, [this.$slots['txtHead'] ? _vm._t("txtHead") : _vm._e(), this.$slots['txt'] ? _c('txt-primary', {
    class: [_vm.size == 'lg' ? 'txt--lg' : '', _vm.txtClass],
    attrs: {
      "name": "txtHead"
    }
  }, [_vm._t("txt")], 2) : _vm._e(), this.$slots['txtFoot'] ? _vm._t("txtFoot") : _vm._e()], 2)], 1) : _c('v-row', {
    staticClass: "txt-items",
    class: _vm.itemsSize
  }, [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }