var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-page-section-basic', _vm._b({
    staticClass: "main-section-primary",
    scopedSlots: _vm._u([{
      key: "pageSectionHead",
      fn: function () {
        return [_vm._t("pageSectionHead")];
      },
      proxy: true
    }, {
      key: "pageSectionFoot",
      fn: function () {
        return [_vm._t("pageSectionFoot")];
      },
      proxy: true
    }], null, true)
  }, 'u-page-section-basic', _vm.$attrs, false), [this.$slots['tit'] ? _c('tit-wrap-primary', {
    attrs: {
      "titWrapClass": _vm.titWrapClass,
      "titWrapSize": _vm.titWrapSize,
      "titWrapAlign": _vm.titWrapAlign,
      "titClass": _vm.titClass,
      "titSize": _vm.titSize,
      "txtClass": _vm.txtClass,
      "txtSize": _vm.txtSize
    },
    scopedSlots: _vm._u([{
      key: "txt",
      fn: function () {
        return [_vm._t("txt")];
      },
      proxy: true
    }], null, true)
  }, [_vm._t("tit")], 2) : _vm._e(), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }