import axios from "@/plugins/axios";

let url = "/api/console/apply/limitations";

export default {
    get(data){
        return axios.get(url, data).then(result => result.data);
    },
    post(data){
        return axios.post(url, data).then(result => result.data);
    },
    put(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
}
