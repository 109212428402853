var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-inline-group', _vm._l(_vm.infos, function (info) {
    return _c('li', {
      key: info.title,
      class: info.clear == true ? 'clear' : ''
    }, [_c('u-txt-default', {
      staticClass: "txt--sm grey--text text--lighten-3"
    }, [_vm._v(" " + _vm._s(info.title) + " "), _c('span', {
      staticClass: "grey--text text--lighten-2"
    }, [_vm._v(_vm._s(info.text))])])], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }