import Vue from "vue";
import Vuetify from "vuetify";

// VCurrencyField
import VCurrencyField from "v-currency-field";
Vue.use(VCurrencyField, {
    locale: "kr-KR",
    decimalLength: 0,
    autoDecimalMode: false,
    defaultValue: 0,
    min: null,
    max: null,
    valueAsInteger: false,
    allowNegative: true,
});

Vue.use(Vuetify);

export const basicTheme = {
    themes: {
        light: {
            primary: {
                lighten5: "#F6E5EE",
                lighten4: "#E8BED6",
                lighten3: "#D993BA",
                lighten2: "#C9689E",
                lighten1: "#BE478A",
                base: "#B22775",
                darken1: "#AB236D",
                darken2: "#A21D62",
                darken3: "#991758",
                darken4: "#8A0E45",
            },
            secondary: {
                lighten5: "#F0E7EF",
                lighten4: "#DAC2D7",
                lighten3: "#C19ABC",
                lighten2: "#A872A1",
                lighten1: "#95538D",
                base: "#823579",
                darken1: "#7A3071",
                darken2: "#6F2866",
                darken3: "#65225C",
                darken4: "#521649",
            },
            accent: {
                lighten5: "#EBE7F7",
                lighten4: "#CCC2EC",
                lighten3: "#AB9AE0",
                lighten2: "#8972D3",
                lighten1: "#6F53C9",
                base: "#5635C0",
                darken1: "#4F30BA",
                darken2: "#4528B2",
                darken3: "#3C22AA",
                darken4: "#2B169C",
            },
            grey: {
                lighten5: "#f7f7f7",
                lighten4: "#EBEBEC",
                lighten3: "#CDCCCF",
                lighten2: "#ACAAB0",
                lighten1: "#716F78",
                base: "#585560",
                darken1: "#504E58",
                darken2: "#47444E",
                darken3: "#333138",
                darken4: "#222026",
            },
            red: {
                base: "#f44336",
                lighten1: "#E35F48",
            },
            blue: {
                darken4: "#0047B0",
            },
            amber: {
                lighten1: "#FFB614",
            },
            success: "#4CAF50",
            warning: "#FFC107",
            error: "#FF5252",
            info: "#2196F3",
            anchor: "inherit",
        },
    },
    options: { customProperties: true },
};

export const consoleTheme = {
    themes: {
        light: {
            primary: "#00C73C",
            secondary: "#445163",
            accent: "#00C73C",
            info: "#2D3539",
            content: "#EDF0F5",
            anchor: "#333",
        },
    },
};

/**
 *
 * @param {(basicTheme|consoleTheme)} theme
 * @returns
 */
export default function (theme) {
    return new Vuetify({
        lang: {
            locales: { ko: require("vuetify/lib/locale/ko") },
            current: "ko",
        },
        breakpoint: {
            mobileBreakpoint: "md",
            thresholds: {
                xs: 576,
                sm: 768,
                md: 1024,
                lg: 1200,
            },
            scrollBarWidth: 0,
        },
        theme: theme || basicTheme,
    });
}
