<template>
    <u-footer-basic class="footer--dark">
        <div class="footer-top">
            <footer-slider />
        </div>
        <u-footer-head-basic>
            <template #left>
                <footer-link />
            </template>
        </u-footer-head-basic>
        <u-footer-body-basic>
            <template #left>
                <v-row class="row--sm">
                    <v-col cols="12">
                        <u-txt-medium class="txt--sm white--text">동대문구 패션봉제지원센터</u-txt-medium>
                    </v-col>
                    <v-col cols="12">
                        <footer-info />
                    </v-col>
                    <v-col cols="12">
                        <u-txt-default class="txt--sm grey--text text--lighten-3">Copyright 2023. DONGDAEMUN FSC. All Rights Reserved.</u-txt-default>
                    </v-col>
                </v-row>
            </template>
            <template #right>
                <v-row justify="space-between" align-md="end" class="flex-column">
                    <v-col cols="auto">
                        <logo-secondary class="ml-md-auto">동대문구 패션봉제지원센터</logo-secondary>
                    </v-col>
                </v-row>
            </template>
        </u-footer-body-basic>
    </u-footer-basic>
</template>

<script>
import LogoSecondary from "@/components/publish/parents/headers/logo-secondary.vue";

import UFooterBasic from "@/sets/styles/footers/u-footer-basic.vue";
import UFooterHeadBasic from "@/sets/styles/footers/u-footer-head-basic.vue";
import UFooterBodyBasic from "@/sets/styles/footers/u-footer-body-basic.vue";
import UFooterFootBasic from "@/sets/styles/footers/u-footer-foot-basic.vue";

import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import FooterSlider from "@/components/publish/styles/slider/footer-slider.vue";
import FooterLink from "@/components/publish/styles/footers/footer-link.vue";
import FooterInfo from "@/components/publish/styles/footers/footer-info.vue";
import IconBlog from "@/components/publish/styles/icons/icon-blog.vue";
import IconYoutube from "@/components/publish/styles/icons/icon-youtube.vue";
import IconFacebook from "@/components/publish/styles/icons/icon-facebook.vue";
import IconInsta from "@/components/publish/styles/icons/icon-insta.vue";

export default {
    components: {
        LogoSecondary,

        UFooterBasic,
        UFooterHeadBasic,
        UFooterBodyBasic,
        UFooterFootBasic,

        UTxtDefault,
        UTxtMedium,
        FooterSlider,
        FooterLink,
        FooterInfo,
        IconBlog,
        IconYoutube,
        IconFacebook,
        IconInsta,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.footer-top {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
</style>
