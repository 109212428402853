var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-notice-slider"
  }, [_c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_vm._l(_vm.notifications, function (notification, index) {
    return _c('swiper-slide', {
      key: index
    }, [_c('card-primary', {
      staticClass: "main-notice-slider__inner",
      attrs: {
        "to": `/notice/${notification._id}`,
        "cardInnerSize": "card-inner--md",
        "innerClass": "pb-42px pb-md-46px h-100"
      }
    }, [_c('div', {
      staticClass: "flex-column h-170px"
    }, [_c('div', [_c('chip-primary4', {
      staticClass: "min-w-60px",
      attrs: {
        "small": ""
      }
    }, [_vm._v("공지")])], 1), _c('div', {
      staticClass: "pt-8px"
    }, [_c('tit-primary', {
      staticClass: "text-truncate",
      attrs: {
        "titSize": "tit--xs"
      }
    }, [_vm._v(_vm._s(notification.subject))])], 1), notification.summary ? _c('div', {
      staticClass: "pt-8px"
    }, [_c('txt-primary', {
      staticClass: "text-truncate-4"
    }, [_vm._v(" " + _vm._s(notification.summary))])], 1) : _vm._e(), _c('div', {
      staticClass: "pt-8px mt-auto"
    }, [_c('txt-primary', [_vm._v(_vm._s(_vm.$dayjs(notification.createdAt).format("YYYY-MM-DD")))])], 1)])])], 1);
  }), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  }, [_c('swiper-btn-wrap-primary', [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-button-secondary')], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('swiper-button-secondary', {
    attrs: {
      "next": ""
    }
  })], 1)], 1)], 1)], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }