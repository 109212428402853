<template>
    <u-tit-wrap-default v-bind="$attrs" :class="[titWrapClass, titWrapSize, titWrapAlign]" class="overflow-hidden">
        <v-row>
            <v-col>
                <u-tit-subtit v-if="this.$slots['subtit']" class="tit--xs" data-aos="fade-right" data-aos-delay="100"><slot name="subtit" /></u-tit-subtit>
                <u-tit-default v-if="this.$slots['tit']" :class="[titClass, titSize]" data-aos="fade-right" data-aos-delay="200"><slot /></u-tit-default>
                <u-tit-default v-else :class="[titClass, titSize]" data-aos="fade-right" data-aos-delay="200"><slot /></u-tit-default>
                <u-txt-default v-if="this.$slots['txt']" :class="[txtClass, txtSize]" data-aos="fade-right" data-aos-delay="300"><slot name="txt" /></u-txt-default>
            </v-col>
            <v-col v-if="this.$slots['colRight']" cols="auto" data-aos="fade-left" data-aos-delay="200">
                <slot name="colRight" />
            </v-col>
        </v-row>
    </u-tit-wrap-default>
</template>

<script>
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {
        titWrapClass: { type: String, default: "" },
        titWrapSize: { type: String, default: "tit-wrap--sm" },
        titWrapAlign: { type: String, default: "text-left" },
        titClass: { type: String, default: "" },
        titSize: { type: String, default: "" },
        txtClass: { type: String, default: "" },
        txtSize: { type: String, default: "" },
    },
    components: {
        UTitWrapDefault,
        UTitDefault,
        UTitSubtit,
        UTxtDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
