var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-card-outlined-tile', _vm._b({
    staticClass: "border-light"
  }, 'u-card-outlined-tile', _vm.$attrs, false), [_vm.innerClass || _vm.cardInnerSize ? [_c('div', {
    staticClass: "card-inner",
    class: [_vm.innerClass, _vm.cardInnerSize]
  }, [_vm._t("default")], 2)] : [_vm._t("default")]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }