var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-tit-wrap-default', _vm._b({
    staticClass: "overflow-hidden",
    class: [_vm.titWrapClass, _vm.titWrapSize, _vm.titWrapAlign]
  }, 'u-tit-wrap-default', _vm.$attrs, false), [_c('v-row', [_c('v-col', [this.$slots['subtit'] ? _c('u-tit-subtit', {
    staticClass: "tit--xs",
    attrs: {
      "data-aos": "fade-right",
      "data-aos-delay": "100"
    }
  }, [_vm._t("subtit")], 2) : _vm._e(), this.$slots['tit'] ? _c('u-tit-default', {
    class: [_vm.titClass, _vm.titSize],
    attrs: {
      "data-aos": "fade-right",
      "data-aos-delay": "200"
    }
  }, [_vm._t("default")], 2) : _c('u-tit-default', {
    class: [_vm.titClass, _vm.titSize],
    attrs: {
      "data-aos": "fade-right",
      "data-aos-delay": "200"
    }
  }, [_vm._t("default")], 2), this.$slots['txt'] ? _c('u-txt-default', {
    class: [_vm.txtClass, _vm.txtSize],
    attrs: {
      "data-aos": "fade-right",
      "data-aos-delay": "300"
    }
  }, [_vm._t("txt")], 2) : _vm._e()], 1), this.$slots['colRight'] ? _c('v-col', {
    attrs: {
      "cols": "auto",
      "data-aos": "fade-left",
      "data-aos-delay": "200"
    }
  }, [_vm._t("colRight")], 2) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }