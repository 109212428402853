import apply from "./apply";
import auction from "./auction";
import banners from "./banners";
import boards from "./boards";
import center from "./center";
import education from "./education";
import equipment from "./equipment";
import fairs from "./fairs";
import forms from "./forms";
import gallery from "./gallery";
import me from "./me";
import lawyers from "./lawyers";
import message from "./message";
import setting from "./setting";
import practice from "./practice";
import schedule from "./schedule";
import shop from "./shop";
import space from "./space";
import terms from "./terms";
import users from "./users";
import popups from "./popups";

export default {
    apply,
    auction,
    banners,
    boards,
    center,
    equipment,
    education,
    fairs,
    forms,
    gallery,
    me,
    lawyers,
    message,
    schedule,
    setting,
    practice,
    shop,
    space,
    terms,
    users,
    popups,
};
