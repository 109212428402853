<template>
    <div class="no-image">
        <u-icon variant="sharp" size="x-large">image_not_supported</u-icon>
    </div>
</template>

<script>
import UIcon from "@/components/publish/styles/icons/u-icon.vue";

export default {
    props: {},
    components: {
        UIcon,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.no-image {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2);
}
</style>
