<template>
    <div class="img-txt-items">
        <v-row class="my-n12px my-md-n20px">
            <v-col v-for="(item, index) in items" :key="index" cols="6" md="4" class="py-12px py-md-20px">
                <!-- <card-tertiary :to="`${to}/${item._id}`"> -->
                <card-tertiary :to="routingPath(item)">
                    <v-img cover :aspect-ratio="282 / 164" :src="item.thumb" class="mx-auto">
                        <template v-slot:placeholder>
                            <no-image />
                        </template>
                    </v-img>
                    <div class="py-16px py-md-24px">
                        <tit-primary titSize="tit--xs" class="mb-8px">{{ item.title }}</tit-primary>
                        <u-txt-items>
                            <v-col cols="12">
                                <u-txt-items txtItem>
                                    <template #tit>이용시간</template>
                                    <template #txt>{{ item.time }}</template>
                                </u-txt-items>
                            </v-col>
                            <v-col cols="12" v-if="item.capacity">
                                <u-txt-items txtItem>
                                    <template #tit>면적</template>
                                    <template #txt>{{ item.capacity }}</template>
                                </u-txt-items>
                            </v-col>
                            <v-col cols="12" v-if="item.quantity">
                                <u-txt-items txtItem>
                                    <template #tit>수량</template>
                                    <template #txt>{{ item.quantity }}</template>
                                </u-txt-items>
                            </v-col>
                            <v-col cols="12">
                                <u-txt-items txtItem>
                                    <template #tit>위치안내</template>
                                    <template #txt>{{ item.location }}</template>
                                </u-txt-items>
                            </v-col>
                        </u-txt-items>
                    </div>
                </card-tertiary>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { DDM_WORK_TYPES } from "@/assets/variables"
import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import CardTertiary from "@/components/publish/parents/cards/card-tertiary.vue";

import UTxtItems from "@/components/publish/styles/items/u-txt-items.vue";
import NoImage from "@/components/publish/styles/cards/no-image.vue";

export default {
    props: {
        items: Array,
        imgTxtItem: Boolean,
    },
    components: {
        TitPrimary,
        TxtPrimary,
        CardTertiary,

        UTxtItems,
        NoImage,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {
        routingPath(item){
            switch(item.work){
                case DDM_WORK_TYPES.CUTTINGROOM.value:
                    return `/cuttingroom-equipment/${item._id}`;
                case DDM_WORK_TYPES.STUDIO.value:
                    return `/studio-equipment/${item._id}`;
                case undefined:
                    return `/facility-space/${item._id}`
            }
        }
    },
};
</script>

<style lang="scss" scoped></style>
