var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "no-image"
  }, [_c('u-icon', {
    attrs: {
      "variant": "sharp",
      "size": "x-large"
    }
  }, [_vm._v("image_not_supported")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }