var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-primary', {
    staticClass: "app--main",
    attrs: {
      "isMain": ""
    }
  }, [_c('main-visual'), _c('main-section-primary', {
    attrs: {
      "titWrapClass": "text-center"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v(" 공간안내 ")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(" 패션봉제지원센터에서 이용가능한 공간을 확인해보세요. ")];
      },
      proxy: true
    }])
  }, [_c('u-img-txt-items', {
    attrs: {
      "items": _vm.spaces
    }
  }), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "v-row--sm",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-text-primary', {
    attrs: {
      "to": "/facility-space/YONGSIN"
    }
  }, [_vm._v("MORE")])], 1)], 1)], 1)], 1), _c('main-section-primary', {
    staticClass: "grey lighten-5",
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v(" 장비안내 ")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(" 패션봉제지원센터에서 이용가능한 장비를 확인해보세요. ")];
      },
      proxy: true
    }])
  }, [_c('u-img-txt-items', {
    attrs: {
      "items": _vm.equipments
    }
  }), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "v-row--sm",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-text-primary', {
    attrs: {
      "to": "/cuttingroom-equipment?code=CUTTINGROOM"
    }
  }, [_vm._v("공용재단실")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('btn-text-primary', {
    attrs: {
      "to": "/studio-equipment/YONGSIN?code=STUDIO"
    }
  }, [_vm._v("공용작업실")])], 1)], 1)], 1)], 1), _c('main-section-primary', {
    scopedSlots: _vm._u([{
      key: "pageSectionHead",
      fn: function () {
        return [_c('div', {
          staticClass: "page-section__bg"
        })];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    staticClass: "row--xl"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('tit-wrap-secondary', {
    scopedSlots: _vm._u([{
      key: "colRight",
      fn: function () {
        return [_c('btn-text-primary', {
          attrs: {
            "to": "/notice"
          }
        }, [_vm._v("MORE")])];
      },
      proxy: true
    }])
  }, [_vm._v(" 공지사항 ")]), _c('main-notice-slider')], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('tit-wrap-secondary', {
    scopedSlots: _vm._u([{
      key: "colRight",
      fn: function () {
        return [_c('btn-text-primary', {
          attrs: {
            "to": "/edu-gallery"
          }
        }, [_vm._v("MORE")])];
      },
      proxy: true
    }])
  }, [_vm._v(" 교육갤러리 ")]), _c('v-row', _vm._l(_vm.galleries, function (gallery, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "6"
      }
    }, [_c('u-card-hover-border-bottom', {
      attrs: {
        "elevation": "15",
        "to": "/edu-gallery"
      }
    }, [_c('v-img', {
      staticClass: "mx-auto",
      attrs: {
        "cover": "",
        "aspect-ratio": 326 / 288,
        "max-width": "324",
        "src": gallery.image
      },
      scopedSlots: _vm._u([{
        key: "placeholder",
        fn: function () {
          return [_c('no-image')];
        },
        proxy: true
      }], null, true)
    })], 1)], 1);
  }), 1)], 1)], 1)], 1), _c('popup-layer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }