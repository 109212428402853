var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-btn-flat-tile', _vm._b({
    attrs: {
      "color": _vm.color
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true)
  }, 'u-btn-flat-tile', _vm.$attrs, false));

}
var staticRenderFns = []

export { render, staticRenderFns }