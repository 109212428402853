var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-btn-icon-flat-circle', _vm._b({
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, 'u-btn-icon-flat-circle', Object.assign({
    color: _vm.color
  }, _vm.$attrs), false), [_c('icon-arrow-primary', _vm._b({
    attrs: {
      "color": _vm.arrowColor
    }
  }, 'icon-arrow-primary', Object.assign({
    size: _vm.size,
    direction: _vm.direction
  }, _vm.$attrs), false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }