<template>
    <u-btn-icon-flat-circle v-bind="{ color, ...$attrs }" @click="$emit('click')">
        <icon-arrow-primary v-bind="{ size, direction, ...$attrs }" :color="arrowColor" />
    </u-btn-icon-flat-circle>
</template>

<script>
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";
import UBtnIconFlatCircle from "@/components/publish/styles/buttons/u-btn-icon-flat-circle.vue";

export default {
    props: {
        color: { type: String, default: "grey darken-2" },
        arrowColor: { type: String, default: "white--text" },
        direction: { type: String, default: "left" },
        size: { type: String, default: "x-small" },
    },
    components: {
        IconArrowPrimary,
        UBtnIconFlatCircle,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
