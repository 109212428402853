var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer-slider"
  }, [_c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return _c('swiper-slide', {
      key: index
    }, [_c('v-card', {
      staticClass: "footer-slider__inner",
      attrs: {
        "flat": "",
        "rounded": "pill"
      }
    }, [_c('img', {
      staticClass: "footer-slider__img",
      attrs: {
        "src": item.img
      }
    })])], 1);
  }), _c('div', {
    staticClass: "swiper-control",
    attrs: {
      "slot": "pagination"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
      }
    },
    slot: "pagination"
  }, [_c('swiper-button-primary'), _c('swiper-button-primary', {
    attrs: {
      "next": ""
    }
  })], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }