<template>
    <div class="main-notice-slider">
        <swiper class="swiper" :options="swiperOptions">
            <swiper-slide v-for="(notification, index) in notifications" :key="index">
                <card-primary :to="`/notice/${notification._id}`" cardInnerSize="card-inner--md" innerClass="pb-42px pb-md-46px h-100" class="main-notice-slider__inner">
                    <div class="flex-column h-170px">
                        <div>
                            <chip-primary4 small class="min-w-60px">공지</chip-primary4>
                        </div>
                        <div class="pt-8px">
                            <tit-primary titSize="tit--xs" class="text-truncate">{{ notification.subject }}</tit-primary>
                        </div>
                        <div v-if="notification.summary" class="pt-8px">
                            <txt-primary class="text-truncate-4"> {{ notification.summary }}</txt-primary>
                        </div>
                        <div class="pt-8px mt-auto">
                            <txt-primary>{{ $dayjs(notification.createdAt).format("YYYY-MM-DD") }}</txt-primary>
                        </div>
                    </div>
                </card-primary>
            </swiper-slide>

            <div slot="pagination" class="swiper-control">
                <swiper-btn-wrap-primary>
                    <v-row class="row--xxs">
                        <v-col cols="auto">
                            <swiper-button-secondary />
                        </v-col>
                        <v-col cols="auto">
                            <swiper-button-secondary next />
                        </v-col>
                    </v-row>
                </swiper-btn-wrap-primary>
            </div>
        </swiper>
    </div>
</template>

<script>
import api from "@/api";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import SwiperPaginationPrimary from "@/components/publish/parents/swipers/swiper-pagination-primary.vue";
import SwiperBtnWrapPrimary from "@/components/publish/parents/swipers/swiper-btn-wrap-primary.vue";
import SwiperButtonPrimary from "@/components/publish/parents/swipers/swiper-button-primary.vue";
import SwiperButtonSecondary from "@/components/publish/parents/swipers/swiper-button-secondary.vue";
import BtnArrowPrimary from "@/components/publish/parents/buttons/btn-arrow-primary.vue";
import CardPrimary from "@/components/publish/parents/cards/card-primary.vue";
import ChipPrimary4 from "@/components/publish/parents/chips/chip-primary4.vue";
import TitPrimary from "@/components/publish/parents/typography/tit-primary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        SwiperPaginationPrimary,
        SwiperButtonSecondary,
        SwiperBtnWrapPrimary,
        SwiperButtonPrimary,
        BtnArrowPrimary,
        CardPrimary,
        ChipPrimary4,
        TitPrimary,
        TxtPrimary,
    },
    props: {},
    data() {
        return {
            swiperOptions: {
                effect: "fade",
                allowTouchMove: true,
                centeredSlides: true,
                spaceBetween: 0,
                autoplay: {
                    delay: 7000,
                    disableOnInteraction: false,
                },
                speed: 700,
                loop: true,
                navigation: {
                    nextEl: ".main-notice-slider .swiper-button-next",
                    prevEl: ".main-notice-slider .swiper-button-prev",
                },
            },
            notifications: []
        };
    },
    mounted() {
        this.search();
    },
    methods: {
        async search(){
            try{
                let { summary, notifications } = await api.v1.center.notifications.gets({
                    params: { isMain: true }
                })
                this.notifications = notifications; 
            } catch(error){
                console.error(error)
                alert(error.response ? error.response.data.message : error.message);
            }
        }
    },
};
</script>

<style lang="scss" scoped>
// main-notice-slider
.main-notice-slider {
    width: 100%;
    &__inner {
        position: relative;
        display: block;
        width: 100%;
        height: 240px;
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: 166px 72px;
        background-image: url(/images/main/infinite-bg.png);
    }
    .v-card.v-sheet--outlined {
        background-color: #fff !important;
    }

    .swiper-control {
        max-width: none;
        width: auto;
        height: auto;
        top: auto;
        left: auto;
        right: 16px;
        bottom: 16px;
        transform: none;
        .swiper-button-next,
        .swiper-button-prev {
            transform: none;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-notice-slider {
        &__inner {
            height: 288px;
        }

        .swiper-control {
            right: 26px;
            bottom: 26px;
        }
    }
}
@media (min-width: 1024px) {
    .main-notice-slider {
        .swiper-control {
            right: 40px;
            bottom: 40px;
        }
    }
}
</style>
