var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-mobile-header-fixed', [_c('u-mobile-header-body-basic', {
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('logo-primary', [_vm._v("동대문구 패션봉제지원센터")])];
      },
      proxy: true
    }, {
      key: "right",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--sm",
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('btn-hamburger-primary', {
          staticClass: "btn-hamburger--mobile"
        }, [_c('span', {
          staticClass: "d-sr-only"
        }, [_vm._v("모바일 메뉴 열기")])])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }