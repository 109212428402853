<template>
    <u-swiper-pagination-filled-outlined v-bind="$attrs" class="swiper-pagination-primary">
        <slot />
    </u-swiper-pagination-filled-outlined>
</template>

<script>
import USwiperPaginationFilledOutlined from "@/components/publish/styles/swipers/u-swiper-pagination-filled-outlined.vue";

export default {
    props: {},
    components: {
        USwiperPaginationFilledOutlined,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.swiper-pagination-primary {
    ::v-deep {
        .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
            border: 1px solid #fff !important;
        }
    }
}
</style>
