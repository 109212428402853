<template>
    <u-btn-text-tile v-bind="$attrs" color="primary" class="btn-text-primary" @click="$emit('click')">
        <slot />
        <icon-plus size="xx-small" class="ml-6px" />
    </u-btn-text-tile>
</template>

<script>
import UBtnTextTile from "@/components/publish/styles/buttons/u-btn-text-tile.vue";
import IconPlus from "@/components/publish/styles/icons/icon-plus.vue";

export default {
    props: {},
    components: {
        UBtnTextTile,
        IconPlus,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.btn-text-primary {
    justify-content: space-between;
    font-weight: 600;
    padding-left: 2px !important;
    padding-right: 2px !important;
    border-bottom: 2px solid var(--v-primary-base);
}
</style>
