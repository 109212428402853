<template>
    <footer class="footer">
        <slot />
    </footer>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.footer {
    &--dark {
        color: #fff;
        background-color: var(--v-grey-base);
    }
}
</style>
