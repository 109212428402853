<template>
    <div class="main-visual">
        <swiper class="swiper" :options="swiperOptions">
            <swiper-slide>
                <v-card flat rounded="0" class="main-visual__inner">
                    <div class="main-visual__content">
                        <div data-aos="zoom-in" data-aos-duration="1000">
                            <div class="font-size-16 font-size-md-18 font-size-lg-24"><b class="font-size-18 font-size-md-20 font-size-lg-36">동대문구 패션봉제복합지원센터</b>는 <br />스마트 제조장비, 공용작업장, 제품개발, 일감연계, 소공인 역량강화, 온라인 마케팅 등 <br class="d-none d-sm-block" />지원을 통해 <b>소공인 혁신기반을 조성</b>합니다.</div>
                        </div>
                    </div>
                    <div class="main-visual__img" style="background-image: url(/images/main/main-visual.jpg)" />
                </v-card>
            </swiper-slide>
            <swiper-slide>
                <v-card flat rounded="0" class="main-visual__inner">
                    <div class="main-visual__content">
                        <div data-aos="zoom-in" data-aos-duration="1000">
                            <div class="font-size-16 font-size-md-18 font-size-lg-24"><b class="font-size-18 font-size-md-20 font-size-lg-36">동대문구 패션봉제복합지원센터</b>는 <br />스마트 제조장비, 공용작업장, 제품개발, 일감연계, 소공인 역량강화, 온라인 마케팅 등 <br class="d-none d-sm-block" />지원을 통해 <b>소공인 혁신기반을 조성</b>합니다.</div>
                        </div>
                    </div>
                    <div class="main-visual__img" style="background-image: url(/images/main/main-visual2.jpg)" />
                </v-card>
            </swiper-slide>
            </swiper-slide>

            <div class="swiper-control" slot="pagination" @click.prevent>
                <swiper-pagination-primary />
            </div>
        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import SwiperPaginationPrimary from "@/components/publish/parents/swipers/swiper-pagination-primary.vue";
import SwiperBtnWrapPrimary from "@/components/publish/parents/swipers/swiper-btn-wrap-primary.vue";
import SwiperButtonPrimary from "@/components/publish/parents/swipers/swiper-button-primary.vue";
import BtnArrowPrimary from "@/components/publish/parents/buttons/btn-arrow-primary.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        SwiperPaginationPrimary,
        SwiperBtnWrapPrimary,
        SwiperButtonPrimary,
        BtnArrowPrimary,
    },
    props: {},
    data() {
        return {
            swiperOptions: {
                allowTouchMove: true,
                centeredSlides: true,
                spaceBetween: 0,
                autoplay: {
                    delay: 7000,
                    disableOnInteraction: false,
                },
                speed: 700,
                loop: true,
                pagination: {
                    el: ".main-visual .swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".main-visual .swiper-button-next",
                    prevEl: ".main-visual .swiper-button-prev",
                },
                on: {
                    slideChangeTransitionStart: function () {
                        var visual = document.querySelector(".main-visual");
                        var slide = visual.querySelector(".swiper-slide-active");
                        var slideAos = slide.querySelectorAll(".aos-init");
                        slideAos.forEach((item) => {
                            item.style.display = "none";
                            item.classList.remove("aos-animate");
                        });
                    },
                    slideChangeTransitionEnd: function () {
                        var visual = document.querySelector(".main-visual");
                        var slide = visual.querySelector(".swiper-slide-active");
                        var slideAos = slide.querySelectorAll(".aos-init");
                        slideAos.forEach((item) => {
                            item.style.display = "";
                        });
                        AOS.init();
                    },
                },
            },
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// main-visual
.main-visual {
    width: 100%;
    &__inner {
        position: relative;
        display: block;
        // max-width: 1920px;
        width: 100%;
        padding-top: calc(2 / 3 * 100%);
        margin: auto;
    }
    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: calc(var(--container) + var(--container-gutter) * 2);
        padding: 0 var(--container-gutter) 10px;
        color: #fff;
        text-align: center;
    }
    &__img {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.25);
    }
    .theme--light.v-card {
        background-color: transparent;
    }
}
@media (min-width: 576px) {
    .main-visual {
        &__inner {
            padding-top: calc(2 / 4 * 100%);
        }
    }
}
@media (min-width: 768px) {
    .main-visual {
        &__content {
            padding-bottom: 0;
        }
    }
}
@media (min-width: 1024px) {
    .main-visual {
        &__inner {
            padding-top: 0;
            height: 582px;
        }
    }
}
</style>
