<template>
    <swiper-button v-bind="$attrs" buttonTag="btn-arrow-primary" class="v-size--xx-small" />
</template>

<script>
import SwiperButton from "@/components/publish/styles/swipers/swiper-button.vue";

export default {
    props: {},
    components: {
        SwiperButton,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
