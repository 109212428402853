var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-btn-text-tile', _vm._b({
    staticClass: "btn-text-primary",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, 'u-btn-text-tile', _vm.$attrs, false), [_vm._t("default"), _c('icon-plus', {
    staticClass: "ml-6px",
    attrs: {
      "size": "xx-small"
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }