<template>
    <!-- S: Mobile Navigation -->
    <u-mobile-nav-basic>
        <u-mobile-nav-head-basic>
            <v-row no-gutters align="center">
                <v-col>
                    <!-- <user-items-primary /> -->
                </v-col>
                <v-col cols="auto">
                    <btn-hamburger-primary class="btn-hamburger--mobile active">
                        <span class="d-sr-only">모바일 메뉴 닫기</span>
                    </btn-hamburger-primary>
                </v-col>
            </v-row>
        </u-mobile-nav-head-basic>
        <u-mobile-nav-body-basic>
            <!-- <v-row v-if="accessToken" no-gutters align="center" class="border-b">
                <v-col>
                    <u-btn-flat-tile block x-large color="grey lighten-5" to="/mypage/order"> 마이페이지 </u-btn-flat-tile>
                </v-col>
                <v-col>
                    <u-btn-flat-tile block x-large color="grey lighten-5" to="/mypage/likes"> 찜하기 </u-btn-flat-tile>
                </v-col>
                <v-col>
                    <u-btn-flat-tile block x-large color="grey lighten-5" to="/mypage/cart"> 장바구니 </u-btn-flat-tile>
                </v-col>
            </v-row> -->

            <gnb gnbName="gnb-mo" />
        </u-mobile-nav-body-basic>
        <!-- <u-mobile-nav-foot-basic>
            <v-row no-gutters align="center" justify="space-between">
                <v-col cols="auto"></v-col>
                <v-col cols="auto">
                    <language-items-secondary />
                </v-col>
            </v-row>
        </u-mobile-nav-foot-basic> -->
    </u-mobile-nav-basic>
</template>

<script>
import UserItemsPrimary from "@/components/publish/parents/headers/user-items-primary.vue";
import LanguageItemsSecondary from "@/components/publish/parents/headers/language-items-secondary.vue";
import BtnHamburgerPrimary from "@/components/publish/parents/buttons/btn-hamburger-primary.vue";

import UMobileNavBasic from "@/sets/styles/headers/u-mobile-nav-basic.vue";
import UMobileNavHeadBasic from "@/sets/styles/headers/u-mobile-nav-head-basic.vue";
import UMobileNavBodyBasic from "@/sets/styles/headers/u-mobile-nav-body-basic.vue";
import UMobileNavFootBasic from "@/sets/styles/headers/u-mobile-nav-foot-basic.vue";
import Gnb from "@/components/publish/styles/headers/gnb.vue";
import UBtnFlatTile from "@/components/publish/styles/buttons/u-btn-flat-tile.vue";

export default {
    components: {
        UserItemsPrimary,
        LanguageItemsSecondary,
        BtnHamburgerPrimary,

        UMobileNavBasic,
        UMobileNavHeadBasic,
        UMobileNavBodyBasic,
        UMobileNavFootBasic,
        Gnb,
        UBtnFlatTile,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {
        logout() {
            if (confirm("로그아웃 하시겠습니까?")) {
                this.$store.dispatch("logout");
            }
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>

<style lang="scss" scoped>
.user-items {
    ::v-deep {
        .v-btn {
            font-size: 1.4rem !important;
        }
    }
}
</style>
