import { render, staticRenderFns } from "./u-img-txt-items.vue?vue&type=template&id=507120d0&scoped=true&"
import script from "./u-img-txt-items.vue?vue&type=script&lang=js&"
export * from "./u-img-txt-items.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "507120d0",
  null
  
)

export default component.exports