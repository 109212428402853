<template>
    <v-row v-if="txtItem" :class="size == 'lg' ? 'row--sm' : itemSize" class="txt-item">
        <v-col :cols="titCols" :md="titMd" :class="[colClass, colTitClass]" class="txt-item__col-tit">
            <txt-secondary :class="[size == 'lg' ? 'txt--lg' : '', titClass]" class="txt--dark" :style="size == 'lg' ? 'min-width:64px' : titWidth"><slot name="tit" /></txt-secondary>
        </v-col>
        <v-col :cols="txtCols" :md="txtMd" :class="[colClass, colTxtClass]" class="txt-item__col-txt">
            <slot v-if="this.$slots['txtHead']" name="txtHead" />
            <txt-primary v-if="this.$slots['txt']" name="txtHead" :class="[size == 'lg' ? 'txt--lg' : '', txtClass]"><slot name="txt" /></txt-primary>
            <slot v-if="this.$slots['txtFoot']" name="txtFoot" />
        </v-col>
    </v-row>
    <v-row v-else :class="itemsSize" class="txt-items">
        <slot />
    </v-row>
</template>

<script>
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
import TxtSecondary from "@/components/publish/parents/typography/txt-secondary.vue";

export default {
    props: {
        txtItem: Boolean,
        size: { type: String, default: "" },
        titCols: { type: String, default: "12" },
        txtCols: { type: String, default: "12" },
        titMd: { type: String, default: "auto" },
        txtMd: { type: String, default: "" },
        colClass: { type: String, default: "" },
        colTitClass: { type: String, default: "" },
        colTxtClass: { type: String, default: "" },
        titWidth: { type: String, default: "min-width:55px" },
        titClass: { type: String, default: "" },
        txtClass: { type: String, default: "" },
        itemSize: { type: String, default: "row--xxs" },
        itemsSize: { type: String, default: "row--xxs" },
    },
    components: {
        TxtPrimary,
        TxtSecondary,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.col-true {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}
@media (max-width: 768px) {
    .txt-items {
        --grid-gutter-xxs: 3px;
    }
    .txt-item {
        &__col-tit {
            padding-bottom: 2px !important;
        }
        &__col-txt {
            &:not(.col-true) {
                padding-top: 0 !important;
            }
        }
    }
}
</style>
