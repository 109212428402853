import faqs from "./faqs";
import notifications from "./notifications";
import questions from "./questions";
import businesses from "./businesses";

export default {
    faqs,
    notifications,
    questions,
    businesses
};
